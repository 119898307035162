.greying {
    width: 100%;
    height: 100%;
    transition: 0.2s ease-in-out;
    z-index: 2;
    opacity: 1;
}

.active-grey {
    opacity: 0.3;
}