@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@100;200;300;400;500;600;700&display=swap');

:root {

    --bg : linear-gradient(to top, #031014, #000312);
    --white : #ffffff;
    --nav : rgba(0, 0, 0, 0.2);
    --purple : #8c52ff;
    --orange : #f89934;
    --red : #d03c3c;
    --blue : #298dd4;
    --dark : #121111;
    --dark2 : #000000;
    --dark-light : #373a47b3;
    --logo: #bbb7b7;
    --selected-color: #298dd4;
 }

 * {
   transition: background-image 0.5s ease-in-out;
   transition: background 0.5s ease-in-out;
   transition: background-color 0.5s ease-in-out;
   transition: color 0.5s ease-in-out;
 }

 body {
    height: 100%;
    margin: 0;
    text-rendering: optimizeLegibility;
    background-image: var(--bg);
    /*overflow-x: hidden;*/
 }

 .body-light {
   background-image: linear-gradient(to top, #e0e0e0, #d1d1d1)!important;
 }
 .border-s-light {
  border: 1px solid var(--dark)!important;
 }
 .border-m-light {
  border: 2px solid var(--dark2)!important;
 }
 .border-RL-light {
  border-right: 2px solid var(--dark2)!important;
  border-bottom: 2px solid var(--dark2)!important;
 }
 .bg-light {
   background-color: var(--logo)!important;
 }
 .background-light {
  background: var(--dark2)!important;
 }
 .text-light {
   color: var(--dark2)!important;
 }

 html {
   scroll-behavior: smooth;
 }