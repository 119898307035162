
.general-title {
    width: 100%;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat, sans-serif;
    margin-top: 4em;
    font-weight:700;
    font-size: 1.2em;
    color: var(--white);
    transition: width 1.5s ease-in-out;
}

.line {
  width: 0vw;
  height: 3px;
  background-color: var(--selected-color);
  margin-left: 10px;
  transition: width 0.7s ease-in-out;
}


.visible {
  width: 20vw;
  transition: width 0.7s ease-in-out;
}


.about-container {
    width:100%;
    box-sizing: border-box;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: Roboto, sans-serif;
    color: var(--white);
    margin-bottom: 50px;
    margin-top: 30px;
}

.about-text {
  font-size: 1.1em;
  width: 60%;
}

.text-link {
  color: var(--selected-color);
  text-decoration: none;
  padding: 3px;
}

.text-link:hover {
  color: var(--blue);
}

.cv-btn {
  font-family: Montserrat, sans-serif;
  font-size: 1.7em;
  margin-top: 50px;
  padding: 10px;
  border: solid 1px var(--selected-color);
  border-radius: 5px;
  background-color: var(--dark2);
  cursor: pointer;
  text-decoration: none;
  color: var(--white);
}

.cv-btn:hover,
.cv-btn:focus {
  background-color: var(--selected-color);
}

@media only screen and (max-width: 550px) {
 .about-text {
  width: 85%;
 }
 .general-title {
  font-size: 1em;
 }
}
  
