.footer-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.footer-links {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.glass-btn {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-left: 1em;
    margin-right: 1em;  
    cursor: pointer;
    transition: all 1s ease-in-out;
  
    &.blue-btn {

      &:hover {
 
        .social-logo {
            transform: scale(1.6);
            transition: all 0.5s ease-in-out;
        }
    }

    }
  
    &.red-btn {

      &:hover {

        .social-logo {
            transform: scale(1.55);
            transition: all 0.5s ease-in-out;
        }
      } 
    }
  
    &.amber-btn {
 
  
      &:hover {

        .social-logo {
            transform: scale(1.6);
            transition: all 0.5s ease-in-out;
        }
      }
    }
  
    &:hover {
      transform: translateY(-10px);
      transition: all 0.5s;
    }
  }

  .reserved {
    font-family: Montserrat, sans-serif;
    width: 50%;
    text-align: center;
    font-size: 0.87em;
    color: var(--white);
  }
  
  #reserved-name {
    font-family: Montserrat, sans-serif;
    color: var(--selected-color);
  }


  @media only screen and (min-width: 650px) and (max-width: 1000px) {
  .footer-links {
    width: 70%;
  }
  }

  @media only screen and (min-width: 450px) and (max-width: 649px) {
    .footer-links {
      width: 90%;
    }
    }

    @media only screen and (max-width: 449px) {
      .footer-links {
        width: 100%;
      }
      }