@keyframes rotation {
  from {
    transform: rotate(0deg); /* La rotation commence à 0 degrés */
  }
  to {
    transform: rotate(
      360deg
    ); /* La rotation se termine à 360 degrés (une rotation complète) */
  }
}

.style-menu {
  position: fixed;
  width: 18%;
  left: -16.1%;
  top: 7rem;
  transition: all 0.5s ease-in-out;
  display: flex;
  z-index: 90;
}

.style-expanded {
  transition: all 0.5s ease-in-out;
  transform: translateX(+89.5%);
}

.style-changer {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: var(--dark);
  border-bottom-right-radius: 5px;
  box-shadow: rgba(245, 244, 244, 0.15) 0px 2px 2px 0px;
}

.switcher-title {
  font-family: Roboto, sans-serif;
  color: var(--white);
  font-weight: 500;
  margin-left: 35px;
}

.switcher-subtitle {
  font-family: Roboto, sans-serif;
  color: var(--logo);
  font-weight: 400;
  font-size: 0.9em;
  margin-left: 35px;
}

.switcher-line {
  width: 80%;
  height: 1px;
  background-color: var(--logo);
  opacity: 0.1;
  margin-left: 35px;
  margin-top: -7px;
}

.style-opener {
  min-height: 100%;
  width: 10%;
}

.gap1 {
  width: 100%;
  height: 10%;
}

.gap2 {
  width: 100%;
  height: 70%;
}

.opener-container {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  margin-left: -1px;
  box-shadow: rgba(245, 244, 244, 0.15) 0px 2px 2px 0px;
}

.opener-logo {
  width: 100%;
  height: auto;
  animation: rotation 2s infinite linear;
}

.colors-container {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 3.2em);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  margin-left: 35px;
}

.color-box {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.style-container {
  box-sizing: border-box;
  display: flex;
  margin-left: 35px;
  margin-bottom: 15px;
}

.style-btn {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--logo);
  border-radius: 5px;
  cursor: pointer;
}

.style-logo {
  width: 70%;
  height: auto;
}

.style-btn:hover,
.active {
  background-color: var(--selected-color);
}

@media only screen and (min-width: 2700px) {
    .style-menu {
        width: 15%;
        left: -13.5%;
      }
  }

@media only screen and (min-width: 2400px) {
    .colors-container {
        grid-template-columns: repeat(5, 3.2em);
        grid-template-rows: repeat(3, 1fr);
      }
  }

@media only screen and (max-width: 1700px) {
  .colors-container {
    grid-template-columns: repeat(3, 3.2em);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1350px) {
    .style-menu {
      width: 25%;
      left: -22.4%;
    }
  }

@media only screen and (min-width: 901px) and (max-width: 1100px) {
  .style-menu {
    width: 30%;
    left: -26.9%;
  }
}

@media only screen and (min-width: 752px) and (max-width: 900px) {
  .style-menu {
    width: 35%;
    left: -31.4%;
  }
}

@media only screen and (max-width: 751px) {
  .style-menu {
    display: none;
  }
}


@media only screen and (max-height: 540px) {
    .style-menu {
      display: none;
    }
}