.banner-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.banner-text {
    display: flex;
    flex-direction: column;
    font-family: Montserrat, sans-serif;
    margin-top: 80px;
    margin-left: 20px;
}


.iam {
    font-size:2.5em;
    font-weight: 300;
    color: var(--white);
}

.name {
    font-size: 5.7em;
    color: var(--white);
    font-weight:700;
    border-radius: 5px;
    margin-top: -20px;
}


/* Media Queries */

/* Screen width of 370px and below */
@media only screen and (max-width: 370px) {
  .name {
    font-size: 3em!important;
  }
  .banner-text {
    margin-left: 20px;
  }
  #job {
    font-size: 1em!important
  }

}

@media only screen and (min-width: 370px) and (max-width: 600px) {
  .name {
    font-size: 4.2em!important;
  }
  .iam {
    font-size: 2em;
  }
  .mouse_scroll {
    margin-top: 40px !important;
  }
  .banner-text {
    margin-left: 40px;
  }
  #job {
    font-size: 0.9em !important;
  }
}



/* Screen width between 600px and 1199px */
@media only screen and (min-width: 600px) and (max-width: 1199px) {
  .name {
    font-size: 6em!important;
  }
  .banner-text {
    margin-left: 70px;
  }
  #job {
    font-size: 1.5em;
  }
}

/* Screen width of 1200px and above */
@media only screen and (min-width: 1200px) {
  .banner-text {
    margin-left: 200px;
  }
  .name {
    font-size: 7em !important;
  }
  .iam {
    font-size: 3em !important;
  }
  #job {
    font-size: 1.7em !important;
  }
}

/* Screen width of 1600px and above */
@media only screen and (min-width: 1600px) {
  .banner-text {
    margin-left: 300px;
  }
  .name {
    font-size: 8em !important;
  }
  #job {
    font-size: 1.8em !important;
  }
}

/* Screen width of 2000px and above */
@media only screen and (min-width: 2000px) {
  .banner-text {
    margin-left: 350px;
  }
  .name {
    font-size: 9em !important;
  }
  #job {
    font-size: 1.9em !important;
  }
}

/* Screen width of 2400px and above */
@media only screen and (min-width: 2400px) {
  .banner-text {
    margin-left: 400px;
  }
  .name {
    font-size: 10em !important;
  }
  #job {
    font-size: 2em !important;
  }
}

@media only screen and (max-height: 550px) {
  .banner-text {
    margin-top: 20px !important;
  }
  .mouse_scroll {
    margin-top: -80px !important;
  }
}

/* Screen height between 551px and 650px */
@media only screen and (min-height: 551px) and (max-height: 650px) {
  .mouse_scroll {
    margin-top: -80px !important;
  }
}

/* Screen height of 700px and below */
@media only screen and (max-height: 700px) {
  .iam {
    font-size: 2em !important;
  }
  .name {
    font-size: 3em ;
  }
}

/* Screen height of 1000px and above */
@media only screen and (min-height: 1000px) {
  .banner-text {
    margin-top: 100px !important;
  }
  .mouse_scroll {
    margin-top: 100px !important;
  }
  #job {
    font-size: 1.5em !important;
  }
  .banner-text {
    margin-top: 200px !important;
    font-size: 1.3em !important;
  }
}

#job-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -15px;
}

#job {
  color: var(--selected-color);
  font-family: Montserrat, sans-serif;
  font-size: 1.2em;
  font-weight:700;
  margin-left: 10px;
}

.banner-logo {
  margin-left: 10px;
  width: 35px;
  height: 35px;
}

.social-banner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top:70px;
  margin-left: 40px;
}

.social-btn {
  transition: 0.4s ease-in-out;
}

.social-btn:hover {
  transform: scale(1.2);
  transition: 0.4s ease-in-out;
}

/* SCROLL DOWN ANIMATED ICON ---------------------------------*/

.mouse_scroll {
	display: block;
	margin: 0 auto;
	width: 24px;
	height: 100px;
  margin-top: -40px;
}

@media only screen and (max-width: 520px) {
  .mouse_scroll{
    margin-top: 50px!important;
  }
  .banner-logo {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (min-height: 800px) and (max-width: 500px) {
.banner-text {
  margin-top: 150px;
}
#job {
  font-size: 1.2em !important;
}
}

@media only screen and (max-height: 550px) and (min-width: 481px) {
    .mouse_scroll{
      margin-top: -80px!important;
      padding-bottom: 50px;
    }
    .name {
      font-size: 5em !important;
    }
}

@media only screen and (max-height: 550px) and (min-width: 600px) {
.banner-text {
  margin-left: 35px !important;
}
}



.m_scroll_arrows
{
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
   
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  
  width: 16px;
  height: 16px;
}


.unu
{
  margin-top: 1px;
}

.unu, .doi, .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  
}

.unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
  
  animation-direction: alternate;
  animation-delay: alternate;
}

.doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .2s;
  animation-direction: alternate;
  
  margin-top: -6px;
}

.trei
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .3s;
  animation-direction: alternate;
  
  
  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: white;
  position: relative;
  height: 4px;
  width: 4px;
  border: 2px solid #fff;
  -webkit-border-radius: 8px;
          border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel{
   0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@-o-keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
/*---------------------------------------------*/
