.project-details {
  width: 50vw;
  position: fixed;
  top: 5%;
  right: 25%;
  left: 25%;
  z-index: 1;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1!important;
}

.show {
  display: flex;
}

.close-project-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--dark);
}


.project-logo-container {
  width: 100%;
  height: 100px;
  background-color: var(--dark);
  display: flex;
  justify-content: center;
  align-items: center;
}
/*
.portfolio-logo {
  max-width: 100%;
  width: 50%;
  height: max-content;
  position: relative;
}
*/

.project-infos {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: var(--white);
}

.project-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin-top: -30px;
}

.project-section-title {
  font-family: Montserrat, sans-serif;
  color: var(--dark);
}

.project-text {
  font-family: Roboto, sans-serif;
  font-size: 0.83em;
}

.project-link-container {
  width: 100%;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
}

.link-logo {
  width: 50px;
  height: 50px;
  cursor:pointer;
}


@media only screen and (max-height:640px)  {
  .project-details {
    width: 100vw;
    top: 0%;
    left: 0%;
    position: absolute;
  }
}

@media only screen and (min-height:900px)  {
  .project-logo-container {
    height: 250px;
  }
  .project-details {
    top: 15%;
  }
}



@media only screen and (max-width:1000px) {
    .project-details {
        width: 100vw;
        top: 0%;
        left: 0%;
    }

    .project-infos {
      flex-direction: column;
      align-items: center;
    }
    .project-section {
        width: 80%;
      }
  }

  @media only screen and (min-width:1200px) {
    .project-details {
      width: 40vw;
    }
    .project-section-title {
      font-size: 1em;
    }
    .project-text {
      font-size: 0.93em;
    }
  }

@media only screen and (min-width: 2000px) {
 
  .project-logo-container {
    height: 200px;
  }

  .project-section-title {
    font-size: 1em;
  }
  .project-text {
    font-size: 0.93em;
  }
}


